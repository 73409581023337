// import React, { useRef } from "react"
// import Layout from "../components/layout"
// import parse from "html-react-parser"
// import { GlobalStyles, mediaBreakpoints } from "../styles/styles"
// import { newGlobalStyles } from "../styles/newstyles"
// import TabSwitcher from "../components/tabSwitcher"
// import useOpenPositions from "../hooks/use-openPositions"
// import styled from "@emotion/styled"
// import { careerTabContent } from "../utils/commonUtils"
// import { css } from "@emotion/react"
// import { useTrackVisibilityObserver } from "./../hooks/use-trackVisibilityObserver"
// import SEO from "../components/SEO"
// import useCareerLayout from "../hooks/use-careerLayout"
// import useCareerCarousel from "../hooks/use-careerCarousel"
// import CareerCarousel from "../components/careerCarouselImages"
// import HeroImageSection from "../components/heroImageSection"
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import Img from "gatsby-image"

// const photoContainer = css`
//   height: 100%;
//   transition: 0.3s ease-out;
//   object-fit: cover;
//   margin-top: 4em;
//   width: 100%;
//   &:hover {
//   }
// `
// const Flex = styled.div`
//   display: flex;
//   position: relative;
//   flex-direction: column;
//   @media ${mediaBreakpoints.small} {
//     flex-direction: column;
//   }
// `
// const Banner = styled("div")`
//   display: flex;
//   opacity: 1;
//   justify-content: center;
//   alig-items: center;
//   font-size: 2.25em;
//   font-family: OpenSans-Bold;
//   @media ${mediaBreakpoints.small} {
//     font-size: 1.5rem;
//     margin: 0 0 2rem 0;
//   }
// `

// const CardHeader = styled("div")`
//   text-transform: capitalize;
//   text-align: center;
//   margin: auto;
//   padding: 3em 0em;
//   width: 77%;
//   @media ${mediaBreakpoints.small} {
//     width: 100%;
//     display:flex;
//     justify-content:center;
//   }
// `

// const TextualContent = styled("div")`
//   display: flex;
//   width: 75%;
//   padding: 0em 0 0 4rem ;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   @media ${mediaBreakpoints.small} {
//     width: 90%;
//     margin:auto;
//     display:flex;
//     flex-direction:column;
//     align-items:center;
//     padding:0;
//     padding-top: 4.5rem;
//     padding-bottom:4rem;
//     & h2{
//       font-size:32px;
//     }
//   }
// `

// const CardContainer = styled("div")`
//   display: flex;
//   width: 100%;
//   @media ${mediaBreakpoints.small} {
//     flex-direction: column;
//   }
// `

// const ImageContent = styled("div")`
//   height: 37.5rem;
//   width: 31.25rem;
//   z-index: 1;
//   padding: 0;
//   display: flex;
//   padding-top: 0;
//   @media ${mediaBreakpoints.small} {
//     height: auto;
//     width: 100%;
//     transform: translateX(0%);
//   }
//   position: relative;
// `

// const CareerImageWrapper = styled.div`
//   display: flex;
//   width: 100%;
//   height: 100%;
// `
// const carouselImageStyles = css`
// width:100%;
// height:28.125rem;
// @media ${mediaBreakpoints.small} {

// }
// `

// const Careers = props => {
//   const data = useOpenPositions()
//   const careerData = useCareerLayout()
//   const careerCarouselImage = useCareerCarousel()
//   const tabTitles = data.map(cop => cop.location)
//   const careerRef = useRef(null)
//   const positionRef = useRef(null)


  
//   const [inView3] = useTrackVisibilityObserver(positionRef, {
//     threshold: 0.1,
//   })

//   let settings = {
//     dots: false,
//     infinite:true,
//     speed: 3000,
//     autoplay: true,
//     // autoplaySpeed: 2000,
//     slidesToShow: 3.2,
//     slidesToScroll: 3,
//     responsive: [
//      {
//        breakpoint: 769,
//        settings: {
//          slidesToShow: 1.1,
//          slidesToScroll: 1,
//          infinite: false,
//          dots: true
//        }
//      },
//     ]
//   };

//   return (
//     <Layout bgColor="#00000" theme="dark" duration="1s" pathname="/careers">
//       <SEO
//         title="Piktorlabs | Careers"
//         description="Careers @ Piktorlabs"
//         pathname="/careers"
//         article={false}
//       />
//       {/* Career Page Header - Block One */}
//       <newGlobalStyles.StyledSection
//         theme="light"
//         css={css`
//           @media ${mediaBreakpoints.small} {
//             height: auto;
//           }
//           @media ${mediaBreakpoints.ipadPro} {
//             height: 26.625em;
//           }
//         `}
//         id="careerBanner"
//       >
//         <HeroImageSection
//           imageSrc={careerData?.titleimage?.localFile?.childImageSharp?.photo}
//           imageBgSource={careerData?.mobiletitleimage?.localFile?.childImageSharp?.photo}
//           text={careerData?.title}
//           theme={"dark"}
//           maxMobWidth={"350px"}
//           width='683px'
//         ></HeroImageSection>
//       </newGlobalStyles.StyledSection>
       
//     {/* Career Page Description Content - Block Two */}
//         <newGlobalStyles.StyledSection 
//         css={css`
//        background-color: #242424;
//       `}
//         theme="dark" id="careerDes"> 
//         <newGlobalStyles.DescriptionSection
//           ref={careerRef}
//           mobMaxWidth='100%'
//           mobWidth='100%'
//           css={css`
//             padding-top:6.55rem;
//             @media ${mediaBreakpoints.small} {
//               padding-top:3rem;
//               padding-bottom:3rem;
//             }
//           `}
//         >
//           <Flex>
//             <CardHeader>
//               <h3
//                 css={css`
//                   color: #fff;
//                   font-weight:300;
//                   @media ${mediaBreakpoints.small} {
//                     text-align:center;
//                     width:90%;
//                     font-size:30px;
//                   }
//                 `}
//               >
//                 {parse(careerData?.titlesubheading)}
//                 {}
//               </h3>
//             </CardHeader>
//             <CardContainer>
//               <ImageContent>
//                 <Img
//                   fluid={
//                     careerData?.subheadingimage?.localFile?.childImageSharp
//                       ?.photo
//                   }
//                   css={photoContainer}
//                   imgStyle={{
//                     width: "100%",
//                     height: "100%",
//                     "object-fit": "cover",
//                   }}
//                   alt="subheading-image"
//                 />
//               </ImageContent>
//               <TextualContent>
//                 <h2
//                   css={css`
//                     color: #fff;
//                     margin-bottom:1rem;
//                     width:100%;
//                     @media ${mediaBreakpoints.small} {
//                       text-align:left;
//                       width:100%;
//                     }
//                   `}
//                 >
//                   {careerData?.subheading}
//                 </h2>
//                 <newGlobalStyles.body1
//                   css={css`
//                     color: #fff;
//                     @media ${mediaBreakpoints.small} {
//                       text-align:left;
//                       width:100%;
//                     }
//                   `}
//                 >
//                   {careerData?.subheadingparagragh}
//                 </newGlobalStyles.body1>
//               </TextualContent>
//             </CardContainer>
//           </Flex>
//         </newGlobalStyles.DescriptionSection>
//       </newGlobalStyles.StyledSection>
//       <newGlobalStyles.StyledSection
//           theme="light"
//           css={css`
//             width: auto;
//             padding-top: 0;
//           `}
//         > 
//           <Slider 
//           {...settings}
//           >
//             {careerCarouselImage && careerCarouselImage.length>0 && careerCarouselImage.map((item,index)=>{
//               return(
//             <div>
//             <Img 
//               css ={carouselImageStyles}
//               fluid={
//                 item?.image?.childImageSharp
//                   ?.photo}
//                   alt={
//                     (props.item &&
//                       // eslint-disable-next-line no-useless-concat
//                       props.item.name) ||
//                     "preview not available"
//                   }
//                   key={index}
//               />
//               </div>
//               )}
//             )}
//           </Slider>
//         </newGlobalStyles.StyledSection>
//       {/* Career Page Image Content - Block Three */}
//         <newGlobalStyles.StyledSection
//           theme="light"
//           css={css`
//             width: auto;
//             padding-top: 6.55rem;
//           `}
//         > 
//         <newGlobalStyles.DescriptionSection>
//           <Banner inView={inView3}>
//             <h2 
//             css={css`
//             @media ${mediaBreakpoints.small} {
//              font-size:30px;
//             }
//           `}
//             >Open positions</h2>
//           </Banner>
//           <TabSwitcher
//             tabs={tabTitles}
//             content={careerTabContent(data)}
//             inView={inView3}
//           ></TabSwitcher>
//         </newGlobalStyles.DescriptionSection>
//         </newGlobalStyles.StyledSection>
//     </Layout>
//   )
// }

// export default Careers

import React, { useEffect, useState } from "react"
import "../index.css"
import Ustlogo from "../assets/logos/UST-evolve-logo.svg"
import PiktorLogo from "../assets/logos/piktorlabs-logo-updated.svg"

function Careers() {
  const [counter, updateCounter] = useState(4)
  const [coverClass, updateCoverClass] = useState("cover")
  const [middleDivClass, updateMiddleDivClass] = useState("middle-div")
  useEffect(() => {
    if (counter >= 0) {
      setTimeout(() => {
        if (counter > 0) updateCounter(counter - 1)
        if (counter === 4) {
          updateCoverClass("cover fadeOut-four")
        }
        // if (counter === 4) {
        //   updateCoverClass("cover fadeOut-three")
        // } else if (counter === 3) {
        //   // updateCoverClass("cover fadeOut-two")
        // } else if (counter === 2) {
        //   updateCoverClass("cover fadeOut-one")
        // } else
        else if (counter === 1) {
          // updateCoverClass("cover fadeOut-zero")
          updateMiddleDivClass("middle-div middle-div-to-center")
        } else if (counter === 0) {
          window.location.replace("https://www.ust.com/en/evolve")
        }
      }, 2000)
    }
  })

  return (
    <div className="App">
      <div className={coverClass}></div>
      <div className="logo">
        <img src={Ustlogo} />
      </div>

      <div className={middleDivClass}>
        <div className="vertical-line">
          <img src={PiktorLogo} />
        </div>
        <div className="right-div">
          <div className="right-div-header">
            Farewell, Piktorlabs! Now part of UST Evolve.
          </div>
          <div className="right-div-middle">
            <p>{counter}</p>
          </div>
          <div className="right-div-footer">
            click <a href="https://www.ust.com/en/evolve">here</a> to visit the
            UST home page.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Careers